import React from "react";

import Router from "./router/router";
import { store } from './redux/store';
import { Provider } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <Router />
      {/* </PersistGate> */}
    </Provider>
  );
};

export default App;
