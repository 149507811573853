import React, { useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import GContainer from "../component/GContainer";

function createData(name, email, address, phone, avatar) {
  return { name, email, address, phone, avatar };
}

// Dummy data with images
const initialRows = [
  createData('John Doe', 'john@example.com', '123 Main St, Anytown, USA', '555-1234', 'https://via.placeholder.com/40'),
  createData('Jane Smith', 'jane@example.com', '456 Maple Ave, Othertown, USA', '555-5678', 'https://via.placeholder.com/40'),
  createData('Alice Johnson', 'alice@example.com', '789 Oak St, Somewhere, USA', '555-8765', 'https://via.placeholder.com/40'),
  createData('Bob Brown', 'bob@example.com', '101 Pine St, Elsewhere, USA', '555-4321', 'https://via.placeholder.com/40'),
];

function UserTableWithEditModal() {
  const [rows, setRows] = useState(initialRows);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
  });

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setFormData(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    const updatedRows = rows.map((row) =>
      row === selectedRow ? { ...formData, avatar: row.avatar } : row
    );
    setRows(updatedRows);
    handleClose();
  };

  return (
    <GContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        User Data Table with Edit Modal
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Profile Picture</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} onClick={() => handleClickOpen(row)} style={{ cursor: 'pointer' }}>
                <TableCell component="th" scope="row">
                  <Avatar alt={row.name} src={row.avatar} />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.address}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit User Data</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </GContainer>
  );
}

export default UserTableWithEditModal;
