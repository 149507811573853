import * as React from "react";
// import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from '@mui/material/styles';

export default function SitemarkIcon() {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    // <SvgIcon sx={{ height: 21, width:90,}}>
      <svg
      color="red"
        fill={isDarkMode ?'white':"black"}
        height={25}
      >
          <g>
            <polygon points="0,0.663 9.401,0.663 15.882,7.146 15.882,14.127 5.307,3.608 5.274,22.969 0,22.969" />
            <polygon points="23.631,22.969 14.232,22.969 7.752,16.485 7.752,9.501 18.327,20.018 18.359,0.662 23.631,0.662" />
          </g>
        <text
          x="27" y="22"
          fontFamily="Orbitron"
          fontSize="15"
          fill={isDarkMode ? 'white':"black"}
        >
          input
        </text>
      </svg>
    // </SvgIcon>
  );
}
