import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
console.log('Login')
  const handleLogin = () => {
    if (username === 'admin' && password === 'password') {
      dispatch({ type: 'LOGIN' });
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Username"
      />
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password"
      />
      <button onClick={handleLogin}>Register</button>
    </div>
  );
};

export default Register;
