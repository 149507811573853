import React, { useState } from "react";
import {
  Grid,
  Card,
  Divider,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Modal,
  Box,
  useTheme,
  IconButton,
  Paper,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GContainer from "../component/GContainer";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const initialItems = [
  {
    id: "1",
    title: "الفطائر",
    content: [
      {
        id: "1-1",
        name: "فطيرة جبن",
        price: 30,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "1-2",
        name: "فطيرة زعتر",
        price: 8,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "1-3",
        name: "فطيرة بطيخ",
        price: 120,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "1-5",
        name: "اكل",
        price: 30,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "1-6",
        name: "شغلة طيبة",
        price: 23,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
    ],
  },
  {
    id: "2",
    title: "المشاوي",
    content: [
      {
        id: "2-1",
        name: "كباب لحم",
        price: 30,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "2-2",
        name: "كباب دجاج",
        price: 2,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
    ],
  },
  {
    id: "3",
    title: "المشروبات",
    content: [
      {
        id: "3-1",
        name: "عوار قلب",
        price: 33,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "3-2",
        name: "ببسي",
        price: 2,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
      {
        id: "3-3",
        name: "ماء",
        price: 1,
        image:
          "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      },
    ],
  },
];
const menu = [
  {
    titleEn: "tea",
    titleAr: "شاي",
    descriptionEn: "best Tea ever",
    descriptionAr: "أفضل شاي على الإطلاق",
    price: "12",
    images: [
      "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
      "https://cdn.pixabay.com/photo/2017/11/11/20/07/food-2940279_1280.jpg",
    ],
  },
  {
    titleEn: "V60",
    titleAr: "V60",
    descriptionEn: "best Tea ever",
    descriptionAr: "أفضل شاي على الإطلاق",
    price: "12",
    images: [],
  },
];

function MenuPage() {
  const [menuItems, setMenuItems] = useState(menu);
  const [items, setItems] = useState(initialItems);
  const theme = useTheme();

  const onDragEnd = (result, outerIndex = null) => {
    const { source, destination } = result;

    if (!destination) return;

    if (outerIndex === null) {
      const reorderedItems = Array.from(items);
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);
      setItems(reorderedItems);
    } else {
      const innerItems = Array.from(items[outerIndex].content);
      const [removed] = innerItems.splice(source.index, 1);
      innerItems.splice(destination.index, 0, removed);
      const updatedItems = [...items];
      updatedItems[outerIndex].content = innerItems;
      setItems(updatedItems);
    }
  };

  const language = useSelector((state) => state.Language.slctdLng);
  return (
    <GContainer>
      <Box
        display="flex"
        sx={{ width: "100%" }}
        alignItems="center"
        flexDirection={language.langRowRvrs}
        justifyContent="space-between"
        // mb={2}
      >
        <Typography variant="h4" gutterBottom>
          {language.menusTxt}
        </Typography>
        <IconButton
          color="primary"
          // onClick={handleOpen}
        >
          <AddIcon color="primary" />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "16px",
          // [theme.breakpoints.down("sm")]: {
          //   padding: "8px",
          // },
        }}
      >
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="accordion-list">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, outerIndex) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={outerIndex}
                  >
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: "block",
                          marginBottom: "12px",
                          padding: "0",
                          "&:hover": {
                            transform: "scale(1.02)",
                            transition: "transform 0.2s ease-in-out",
                          },
                          transition: "transform 0.2s ease-in-out",
                        }}
                      >
                        <Accordion
                          sx={{
                            width: "100%",
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            overflow: "hidden",
                            "&:before": {
                              display: "none",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              bgcolor: theme.palette.primary,
                              color: theme.palette.primary,
                              direction: language.langDir,
                              padding: { xs: "12px", sm: "16px" },
                              "& .MuiAccordionSummary-content": {
                                margin: 0,
                              },
                            }}
                          >
                            <Typography variant="h6">{item.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: { xs: "12px", sm: "16px" },
                              backgroundColor: theme.palette.background.default,
                            }}
                          >
                            <DragDropContext
                              onDragEnd={(result) =>
                                onDragEnd(result, outerIndex)
                              }
                            >
                              <Droppable
                                droppableId={`droppable-${item.id}`}
                                direction="horizontal"
                              >
                                {(provided) => (
                                  <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{
                                      display: "flex",
                                      gap: "12px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {item.content.map((subItem, innerIndex) => (
                                      <Draggable
                                        key={subItem.id}
                                        draggableId={subItem.id}
                                        index={innerIndex}
                                      >
                                        {(provided) => (
                                          <Paper
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                              backgroundColor: theme.palette.background.paper,
                                              boxShadow: "none", // Remove shadow
                                              borderWidth: 1,
                                              borderColor: "#E7E9EF",
                                              borderStyle: "solid", // Make sure border is visible
                                              borderRadius: "2px", // Border radius for rounded corners
                                              width: "150px",
                                              textAlign: "center",
                                              overflow: "hidden",
                                              transition: "transform 0.3s ease",
                                              "&:hover": {
                                                transform: "scale(1.05)",
                                              },
                                            }}
                                          >
                                            {/* Item Image */}
                                            <Box
                                              component="img"
                                              src={subItem.image} // Assuming each subItem has an 'image' property with the URL
                                              alt={subItem.name}
                                              sx={{
                                                width: "100%",
                                                height: "100px",
                                                objectFit: "cover",
                                                borderRadius: "1px",
                                                mb: 2,
                                              }}
                                            />
                                            {/* Item Name */}
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                fontSize: "1rem",
                                                fontWeight: 600,
                                                color:
                                                  theme.palette.text.primary,
                                                mb: 1,
                                              }}
                                            >
                                              {subItem.name}
                                            </Typography>
                                            {/* Item Price */}
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                fontSize: "0.9rem",
                                                color:
                                                  theme.palette.primary.main,
                                                fontWeight: 700,
                                              }}
                                            >
                                              &#65020;{subItem.price}{" "}
                                            </Typography>
                                          </Paper>
                                        )}
                                      </Draggable>
                                    ))}

                                    {provided.placeholder}
                                  </Box>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </GContainer>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const imageModalStyle = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  maxWidth: "80%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default MenuPage;
