import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
  AppBar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
 
  Language,
  Logout,
  Menu as MenuIcon,
  Home,
  TableBar,Restaurant,SpaceDashboard
} from "@mui/icons-material";
import { ar } from "../../lang/ar";
import { en } from "../../lang/en";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;

const PermanentDrawer = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Language.slctdLng);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNavigation = (path) => {
    navigate(path);
    setMobileOpen(false); // Close the drawer on mobile after navigation
  };

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (val) => {
    if (val === "close") {
      setAnchorEl(null);
    } else {
      val === "ar"
        ? dispatch({ type: "CHANGE_LANG", payload: ar })
        : dispatch({ type: "CHANGE_LANG", payload: en });
    }
    setAnchorEl(null); // Close the menu
  };

  const handleLogout = () => {
    // Add your logout logic here
    dispatch({ type: "LOGOUT" });
    setMobileOpen(false); // Close the drawer on mobile after logout
  };

  const drawerContent = (
    <Box>
      <CssBaseline />
      {isMobile && <Toolbar />}
      <Box>
        <List>
          <ListItem
            button
            onClick={() => handleNavigation("/merchant")}
            sx={styles.item}
            dir={language.langDir}
          >
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "white", textAlign: language.langAlign }}
              primary={language.home}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => handleNavigation("/merchant/tables")}
            sx={styles.item}
            dir={language.langDir}
          >
            <ListItemIcon>
              <TableBar />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "white", textAlign: language.langAlign }}
              primary={language.Tables}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => handleNavigation("/merchant/menus")}
            sx={styles.item}
            dir={language.langDir}
          >
            <ListItemIcon>
              <SpaceDashboard />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "white", textAlign: language.langAlign }}
              primary={language.menusTxt}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => handleNavigation("/merchant/contact")}
            sx={styles.item}
            dir={language.langDir}
          >
            <ListItemIcon>
              <Restaurant />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "white", textAlign: language.langAlign }}
              primary={language.Restaurants}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
  const bottom = (
    <Box sx={{ display: "flex", justifyContent: "space-around", px: 1, m: 1 }}>
      <Box>
        <IconButton
          sx={{ color: "white" }}
          onClick={handleLanguageMenuClick}
          aria-controls="language-menu"
          aria-haspopup="true"
        >
          <Language />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleLanguageMenuClose("close")}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => handleLanguageMenuClose("en")}>
            English
          </MenuItem>
          <MenuItem onClick={() => handleLanguageMenuClose("ar")}>
            العربية
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        <IconButton
          sx={{ color: "white" }}
          color="inherit"
          onClick={handleLogout}
        >
          <Logout />
        </IconButton>
      </Box>
    </Box>
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        direction: language.langDir,
      }}
    >
      <CssBaseline />
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            direction: language.langDir,
            boxShadow: 0,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between", bgcolor: "#d1d3dd" }}>
            <Typography variant="h6" color="#313443" noWrap>
              {language.appTitle}
            </Typography>
            <IconButton
              color="#313443"
              aria-label="open drawer"
              edge="end" // Changed to end for RTL
              onClick={handleDrawerToggle}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isMobile ? (
          <Drawer
            variant="temporary"
            anchor={language.langAlign} // Changed to right for RTL
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                direction: language.langDir,
                bgcolor: "#313443",
                justifyContent: "space-between",
              },
            }}
          >
            {drawerContent}
            {bottom}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            anchor={language.langAlign} // Anchor the drawer to the right
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                bgcolor: "#313443",
                direction: language.langDir,
                justifyContent: "space-between",
              },
            }}
            open
          >
            {drawerContent}
            {bottom}
          </Drawer>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  item: {
    "&:hover": {
      bgcolor: "#1e202c",
      color: "white",
    },
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
};

export default PermanentDrawer;
