

const initialState = {
    isAuthenticated: false,
    info: {
        id: 0,
        group_id: 2,
        full_name: '',
        phone: '',
        contactLang: 'ar',
        super: 0,
        countryPhoneCode: '',
        countryCode: 'SA',
        showPaymentUponReceiptButton: 1,
        setNewPassword: 0,
      },
      fvrdPrdcts: [],
      notifiedPrdcts: [],
      settings: null,
      fabmargin: 50,
      accessToken: '',
      refreshToken: '',
      Data: {},
  };
  
  export const UserData = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          isAuthenticated: true,
        };
      case 'LOGOUT':
        return {
          ...state,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };




