// TableCard.js
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import { GroupsOutlined, Person } from "@mui/icons-material";
// import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import EditTableModal from "./EditTableModal";

const TableCard = ({
  tableNumber,
  initialChairs,
  initialReserved,
  reserverPhone,
  date,
  time,
  count,
  reserverName,
}) => {
  const [chairs, setChairs] = useState(initialChairs);
  const [isReserved, setIsReserved] = useState(initialReserved);
  const [openModal, setOpenModal] = useState(false);
  const [xx, setXx] = useState(1);
  const [phone, setPhone] = useState(reserverPhone);
  const [tableTime, setTableTime] = useState(time);
  const [tableCount, setTableCount] = useState(count);
  const [name, setName] = useState(reserverName);
  const language = useSelector((state) => state.Language.slctdLng);

  const handleEditClick = () => {
    setOpenModal(true);
    setXx(1)
  };
  const handleCloseClick = () => {
    console.log("sdsdsddsdqqq", openModal);
    setOpenModal(false);
    setXx(0)
  };

  const handleSaveChanges = (updatedValues) => {
    setChairs(updatedValues.chairs);
    setIsReserved(updatedValues.isReserved);
    setPhone(updatedValues.reserverPhone);
    setTableTime(updatedValues.time);
    setTableCount(updatedValues.count);
    setName(updatedValues.reserverName);
  };

  const getProgressColor = (progress) => {
    if (progress === 0) {
      return "error";
    } else if (progress === 1) {
      return "info";
    } else {
      return "success";
    }
  };

  const setProgress = (progress) => {
    if (progress === 0) {
      return 100;
    } else if (progress === 1) {
      return 50;
    } else {
      return 100;
    }
  };

  return (
    <Card
      sx={{ borderColor: "gray", borderRadius: 0, borderWidth: 1, margin: 0.1 }}
      onClick={handleEditClick}
    >
      {isReserved === 0 ? (
        <CardContent sx={{height:130}} >
          <Box
            display="flex"
            flexDirection={language.langRowRvrs}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="p" fontSize={13}>
              {tableNumber}#
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={language.langRow}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <GroupsOutlined sx={{ fontSize: 19 }} />
              <Typography
                variant="body1"
                fontSize={13}
                sx={{ ml: 1, display: "flex" }}
              >
                {tableCount}/{chairs}
              </Typography>
            </Box>
          </Box>
          <Box>
            <LinearProgress
              variant="determinate"
              value={setProgress(isReserved)}
              color={getProgressColor(isReserved)}
              sx={{ mb: 2, backgroundColor: "#e0e0e0" }}
            />
          </Box>
        </CardContent>
      ) : (
        <CardContent sx={{height:130}} >
          {/* <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton> */}
          <Box
            display="flex"
            flexDirection={language.langRowRvrs}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="p" fontSize={13}>
              {tableNumber}#
            </Typography>
            <Typography variant="p" fontSize={13}>
              {tableTime}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection={language.langRow}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <GroupsOutlined sx={{ fontSize: 19 }} />
              <Typography
                variant="body1"
                fontSize={13}
                sx={{ ml: 1, display: "flex" }}
              >
                {tableCount}/{chairs}
              </Typography>
            </Box>
          </Box>

          <Box>
            <LinearProgress
              variant="determinate"
              value={setProgress(isReserved)}
              color={getProgressColor(isReserved)}
              sx={{ mb: 2, backgroundColor: "#e0e0e0" }}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={language.langRowRvrs}
          >
            <Avatar>
              <Person />
            </Avatar>
            <Box>
              <Typography
                fontSize={10}
                textAlign={"center"}
                variant="body1"
                mx={1}
              >
                {name}
              </Typography>
              <Typography
                fontSize={9}
                textAlign={"center"}
                variant="body1"
                fontWeight={"bold"}
                style={{ cursor: "pointer" }}
              >
                {phone}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      )}
      {/* <Typography
        fontSize={9}
        textAlign={"center"}
        variant="body1"
        fontWeight={"bold"}
        style={{ cursor: "pointer" }}
      >
        {openModal ? "d" : "1"}
      </Typography> */}
      <EditTableModal
        open={openModal}
        onClose={handleCloseClick}
        tableNumber={tableNumber}
        chairs={chairs}
        xx={xx}
        isReserved={isReserved}
        reserverPhone={phone}
        date={date}
        time={tableTime}
        count={tableCount}
        reserverName={name}
        onSave={handleSaveChanges}
      />
    </Card>
  );
};

export default TableCard;
