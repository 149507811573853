import React from "react";
import {
  Container,
  Box,
  useMediaQuery,
  Toolbar,
  useTheme,
  Stack,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

const GContainer = (props) => {
  // const language = useSelector((state) => state.Language.slctdLng);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="main"
      sx={(theme) => ({
        flexGrow: 1,
        // backgroundColor: theme.vars
        //   ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
        //   : alpha(theme.palette.background.default, 1),
        overflow: "auto",
      })}
    >
      <Stack
        spacing={2}
        sx={{
          // alignItems: "center",
          mx: 3,
          pb: 10,
          mt: { xs: 8, md: 0 },
        }}
      >
        {props.children}
      </Stack>
    </Box>
  );
};

export default GContainer;
