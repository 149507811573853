import React, { useState } from "react";
import {
  Card,
  Divider,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Modal,
  Box,
  ButtonBase,
  IconButton,
  Paper,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AccountTree } from "@mui/icons-material";
import GContainer from "../component/GContainer";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";

function Branches() {
  const language = useSelector((state) => state.Language.slctdLng);
  const location = useLocation();

  const branchData = location.state;
  const onClick = (item) => {
    console.log("Clicked on:", item);
  };
  console.log(branchData);
  return (
    <GContainer>
      <Typography variant="h4" sx={{ alignSelf: "center", fontWeight: "bold" }}>
        {language.langCode == "ar" ? branchData.nameAr : branchData.nameEn}
      </Typography>
      <Divider />
      <Grid container direction={language.langDir} spacing={2}>
        {branchData.branches.map((item, index) => (
          <Grid
            xs={12} // Full width on small screens
            sm={4} // Three boxes on medium screens
            md={2} // Six boxes on large screens
            key={index}
          >
            <ButtonBase
              onClick={() => onClick(item)}
              sx={{
                width: "90%",
                height: "100%",
                p: 1,
                borderColor: "#E7E9EF",
                borderRadius: 1,
                borderWidth: 0.5,
                borderStyle: "solid",
              }}
            >
              <Box
                sx={{
                  display: "flex", // Add flexbox
                  flexDirection: "column", // Stack items vertically
                  alignItems: "center", // Center items horizontally
                  justifyContent: "center", // Center items vertically
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <AccountTree sx={{ fontSize: 50, alignSelf: "center" }} />
                <Typography sx={{ alignSelf: "center", fontWeight: "bold" }}>
                  {language.langCode == "ar" ? item.nameAr : item.nameEn}
                </Typography>
                <Typography sx={{ alignSelf: "center" }}>
                  {item.contact}
                </Typography>
              </Box>
            </ButtonBase>
          </Grid>
        ))}
        <Grid
          xs={12} // Full width on small screens
          sm={4} // Three boxes on medium screens
          md={2} // Six boxes on large screens
        >
          <ButtonBase
            onClick={() => console.log("item")}
            sx={{
              width: "90%",
              height: "100%",
              p: 1,
              bgcolor: "#E7E9EF",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex", // Add flexbox
                flexDirection: "column", // Stack items vertically
                alignItems: "center", // Center items horizontally
                justifyContent: "center", // Center items vertically
                textAlign: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AddIcon sx={{ fontSize: 50, color: "white" }} />
            </Box>
          </ButtonBase>
        </Grid>
      </Grid>
    </GContainer>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const imageModalStyle = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  maxWidth: "80%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default Branches;
