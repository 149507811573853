import React from "react";

import { ButtonBase, Grid, Typography, Box } from "@mui/material";
import GContainer from "../component/GContainer";
import { useSelector } from "react-redux";
import { Language, RestaurantRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const language = useSelector((state) => state.Language.slctdLng);
  const Restaurants = useSelector((state) => state.Restaurant);
  const navigate = useNavigate();

  const onClick = (item) => {
    console.log("Clicked on:", item);
    handleNavigation(item);
  };
  const handleNavigation = (path) => {
    console.log("Clicked path:", path);
    navigate("/merchant/branches", { state: path });
  };
  return (
    <GContainer>
      <Grid container direction={language.langDir} spacing={2}>
        {Restaurants.map((item, index) => (
          <Grid
            xs={12} // Full width on small screens
            sm={4} // Three boxes on medium screens
            md={2} // Six boxes on large screens
            key={index}
          >
            <ButtonBase
              onClick={() => onClick(item)}
              sx={{
                width: "90%",
                height: "100%",
                p: 1,
                borderColor: "#E7E9EF",
                borderRadius: 1,
                borderWidth: 0.5,
                borderStyle: "solid",
              }}
            >
              <Box
                sx={{
                  display: "flex", // Add flexbox
                  flexDirection: "column", // Stack items vertically
                  alignItems: "center", // Center items horizontally
                  justifyContent: "center", // Center items vertically
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <RestaurantRounded sx={{ fontSize: 50, alignSelf: "center" }} />
                <Typography sx={{ alignSelf: "center", fontWeight: "bold" }}>
                  {Language.langCode == "ar" ? item.nameAr : item.nameEn}
                </Typography>
                <Typography sx={{ alignSelf: "center" }}>
                  {item.cuisine}
                </Typography>
              </Box>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </GContainer>
  );
};

export default Home;
