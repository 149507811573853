import * as React from "react";
import { styled } from "@mui/material/styles";
import Divider, { dividerClasses } from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import { paperClasses } from "@mui/material/Paper";
import { listClasses } from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon, { listItemIconClasses } from "@mui/material/ListItemIcon";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import LanguageRounded from "@mui/icons-material/LanguageRounded";

import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MenuButton from "./MenuButton";
import { useSelector, useDispatch } from "react-redux";
import { ar } from "../../../lang/ar";
import { en } from "../../../lang/en";
const MenuItem = styled(MuiMenuItem)({
  margin: "2px 0",
});

export default function OptionsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const language = useSelector((state) => state.Language.slctdLng);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const handleLanguageChange = (val) => {
    val === "ar"
      ? dispatch({ type: "CHANGE_LANG", payload: ar })
      : dispatch({ type: "CHANGE_LANG", payload: en });
  };
  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: "transparent" }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: language.langAlign, vertical: "top" }}
        anchorOrigin={{ horizontal: language.langAlign, vertical: "bottom" }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: "4px",
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: "4px -4px",
          },
        }}
      >
        <MenuItem sx={{ direction: language.langDir }} onClick={handleClose}>
          {language.myProfile}
        </MenuItem>
        <MenuItem sx={{ direction: language.langDir }} onClick={handleClose}>
          {language.ACcountTxt}
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>Settings</MenuItem>
        <Divider /> */}
        <MenuItem
          onClick={() =>
            handleLanguageChange(language.langCode === "ar" ? "en" : "ar")
          }
          sx={{
            direction: language.langCode == "ar" ? "ltr" : "rtl",
            [`& .${listItemIconClasses.root}`]: {
              ml: "auto",
              minWidth: 0,
            },
          }}
        >
          <ListItemText sx={{ textAlign: language.lngAlnRvrs }}>
            {language.langCode == "ar" ? "English" : "العربية"}
          </ListItemText>
          <ListItemIcon>
            <LanguageRounded fontSize="small" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          sx={{
            direction: language.langDir,
            [`& .${listItemIconClasses.root}`]: {
              ml: "auto",
              minWidth: 0,
            },
          }}
        >
          <ListItemText sx={{ textAlign: language.langAlign }}>
            {language.logoutTxt}
          </ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
