// EditTableModal.js
import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Typography,
  Button,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import {  Language } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
const EditTableModal = ({
  open,
  onClose,
  tableNumber,
  chairs,
  isReserved,
  reserverPhone,
  date,
  time,
  count,
  reserverName,
  onSave,xx
}) => {
  const [editedChairs, setEditedChairs] = useState(chairs);
  const [editedReserved, setEditedReserved] = useState(isReserved);
  const [editedReserverPhone, setEditedReserverPhone] = useState(reserverPhone);
  const [editedDate, setEditedDate] = useState(date);
  const [editedTime, setEditedTime] = useState(time);
  const [editedCount, setEditedCount] = useState(count);
  const [editedReserverName, setEditedReserverName] = useState(reserverName);

  const handleSave = () => {
    onSave({
      chairs: editedChairs,
      isReserved: editedReserved,
      reserverPhone: editedReserverPhone,
      date: editedDate,
      time: editedTime,
      count: editedCount,
      reserverName: editedReserverName,
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" mb={2}>
          تعديل الطاولة {tableNumber}
        </Typography>
        <Box display="flex" justifyContent={"space-between"}>
          <TextField
            sx={{ flex: 0.49 }}
            label="الكراسي"
            value={editedChairs}
            onChange={(e) => setEditedChairs(e.target.value)}
            margin="normal"
          />
          <TextField
            sx={{ flex: 0.49 }}
            label="عدد الحضور"
            value={editedCount}
            onChange={(e) => setEditedCount(e.target.value)}
            margin="normal"
          />
        </Box>

        <Box display="flex" justifyContent={"space-between"}>
          <TextField
            sx={{ flex: 0.49 }}
            label="الاسم"
            value={editedReserverName}
            onChange={(e) => setEditedReserverName(e.target.value)}
            margin="normal"
          />
          <TextField
            sx={{ flex: 0.49 }}
            label="الرقم"
            value={editedReserverPhone}
            onChange={(e) => setEditedReserverPhone(e.target.value)}
            margin="normal"
          />
        </Box>

        <Box display="flex" justifyContent={"space-between"}>
          <TextField
            sx={{ flex: 0.49 }}
            label="التاريخ"
            value={editedDate}
            onChange={(e) => setEditedDate(e.target.value)}
            margin="normal"
          />
          <TextField
            sx={{ flex: 0.49 }}
            label="الوقت"
            value={editedTime}
            onChange={(e) => setEditedTime(e.target.value)}
            margin="normal"
          />
        </Box>

        <Typography variant="body1" textAlign={Language.langRow} mt={2}>
          {xx}
        </Typography>
        <Typography variant="body1" textAlign={Language.langRow} mt={2}>
          الحجز:
        </Typography>
        <Select
          value={editedReserved}
          onChange={(e) => setEditedReserved(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value={0}>لا يوجد حجز</MenuItem>
          <MenuItem value={1}>حجز غير مؤكد</MenuItem>
          <MenuItem value={2}>حجز مؤكد</MenuItem>
        </Select>
        <Button onClick={handleSave} variant="contained" sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EditTableModal;
