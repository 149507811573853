const styles = {
    body: {
      margin: 0,
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      background: 'hsl(210, 36%, 96%)',
      color: 'hsl(209, 61%, 16%)',
    },
    code: {
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    },
    headings: {
      letterSpacing: '0.1rem',
      textTransform: 'capitalize',
      lineHeight: 1.25,
      marginBottom: '0.75rem',
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '0.875rem',
    },
    paragraph: {
      marginBottom: '1.25rem',
      color: 'hsl(210, 22%, 49%)',
    },
    section: {
      width: '90vw',
      margin: '0 auto',
      maxWidth: '1170px',
    },
    sectionLarge: {
      width: '95vw',
    },
    menu: {
      padding: '5rem 0',
    },
    title: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    underline: {
      width: '6rem',
      height: '0.25rem',
      background: '#c59d5f',
      margin: '0 auto',
    },
    btnContainer: {
      marginBottom: '4rem',
      display: 'flex',
      justifyContent: 'center',
    },
    filterBtn: {
      background: 'transparent',
      borderColor: 'transparent',
      fontSize: '1rem',
      textTransform: 'capitalize',
      margin: '0 0.5rem',
      letterSpacing: '1px',
      padding: '0.375rem 0.75rem',
      color: 'hsl(360, 71%, 66%)',
      cursor: 'pointer',
      transition: 'all 0.3s linear',
      borderRadius: '0.25rem',
    },
    active: {
      borderBottom: '2px solid',
    },
    sectionCenter: {
      width: '90vw',
      margin: '0 auto',
      maxWidth: '1170px',
      display: 'grid',
      gap: '3rem 2rem',
      justifyItems: 'center',
    },
    menuItem: {
      display: 'grid',
      gap: '1rem 2rem',
      maxWidth: '25rem',
    },
    photo: {
      objectFit: 'cover',
      height: '200px',
      width: '100%',
      border: '0.25rem solid hsl(360, 71%, 66%)',
      borderRadius: '0.25rem',
      display: 'block',
    },
    logo: {
      objectFit: 'cover',
      height: 'auto',
      width: '200px',
      borderRadius: '0.25rem',
    },
    itemInfoHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '0.5px dotted hsl(210, 22%, 49%)',
    },
    itemInfoH4: {
      marginBottom: '0.5rem',
    },
    price: {
      color: '#c59d5f',
    },
    itemText: {
      marginBottom: 0,
      paddingTop: '1rem',
    },
    menuItemLarge: {
      gridTemplateColumns: '225px 1fr',
      gap: '0 1.25rem',
      maxWidth: '40rem',
    },
    photoLarge: {
      height: '175px',
    },
    sectionCenterLarge: {
      width: '95vw',
      gridTemplateColumns: '1fr 1fr',
    },
    photoXLarge: {
      height: '150px',
    },
  };
  
  export default styles;
  