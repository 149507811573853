import React from "react";
import MarketingPage from "./marketing-page/MarketingPage";
import { store } from './redux/store';
import { Provider } from "react-redux";


const Visitor = () => {
  return (
    <Provider store={store}>
      <MarketingPage />
    </Provider>
  );
};

export default Visitor;
