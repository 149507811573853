export const ar = {
  langCode: "ar",
  langDir: "rtl",
  langAlign: "right",
  lngAlnRvrs: "left",
  lngFlex: "flex-end",
  langRow: "row",
  langRowRvrs: "row-reverse",
  appTitle: "تطبيق المطعم",
  visitor: "عزيزي !",
  menusTxt: "القوائم",
  welcome: "أهلا بك",
  Tables:'الطاولات',
  Restaurants:'المطاعم',
  Reserved:'محجوز',
  Available:'متاح',
  Chairs:"الكراسي",
  gram: "بالجرام",
  totalTxt: "الإجمالي",
  EnterEnglishNumberTxt: "تاكد من ادخال الارقام باللغة الانجليزية",
  EnterEnglishTxt: "تاكد من إدخال الاسم باللغة الانجليزية",
  theLanguage: "اللغة",
  AddNewItem:'أضف عنصر جديد',
  UpdateMenuItem:'تعديل العنصر',
  /* menu */
  deleteTxt: "حذف",
  home: "الرئيسية",
  aboutUs: "من نحن",
  loginByEmail: "تسجيل الدخول بالبريد الإلكتروني",
  wlcmlgnTxt: "أهلاً، قم بتسجيل الدخول",
  loginByPhone: "تسجيل الدخول برقم الجوال",
  loginByPwd: "تسجيل الدخول بالرقم السري",
  completeMyData: "إكمال بيناتي",
  yourDataIsIncmplt: "بيانات حسابك غير مكتملة",
  addEmailInMyProfile: "قم بإضافة البريد الإلكتروني من خلال صفحة حسابي",
  policyTxt: "الشروط والأحكام",
  footerMyProfile: "حسابي",
  footerPackages: "باكيجات",
  allProducts: "جميع المنتجات",
  offers: "العروض",
  VerifiedPurchase: "قام بالشراء",

  faq: "الأسئلة الأكثر شيوعا",
  getMyLocation: "حدد موقعي",
  contactUs: "اتصل بنا",
  welcomeContacter: "أهلا بك عزيزي، كيف يمكننا مساعدتك ؟",
  login: "دخول",
  prdctRviewTxt: "ما رأيك بالمنتج ؟",
  dontHvAccnt: " ليس لديك حساب ؟",
  signIn: " تسجيل دخول ",
  alreadyHaveAcct: " لديك حساب بالفعل ؟",
  logoutTxt: "خروج",
  register: "تسجيل",
  noConnectionTxt: "لا يوجد اتصال بالإنترنت",
  updateProfile: "تحديث بياناتي",
  updateApp: "تحديث",
  updateNeeded: "يجب تحديث البرنامج",
  show: "عرض",
  shippingTxt: "الشحن",
  trackTxt: "تتبع",
  loginToCommentTxt: "سجل دخول لترك تعليق",
  changeTxt: "تغيير",
  notAvailableTxt: "نفدت الكمية",
  addedRecentlyTxt: "أحدث المنتجات",
  mostSoldTxt: "الأكثر مبيعا",
  more: "المزيد",
  less: "أقل",
  threelettersWarnings: "يجب ان لا يقل حقل العنوان عن 3 احرف",
  MyAdressesTxt: "عناويني",
  checkRecieverName: "تأكد من اسم المستلم",
  checkfullName: "تأكد من الاسم",
  logOutDoubleCheck: "هل انت متأكد من تسجيل الخروج",
  logOutFullText: "تسجيل الخروج",
  enterNameOnCardTxt: "أدخل الاسم على البطاقة",
  /* product details */
  priceIncludeTaxes: "شامل ضريبة القيمة المضافة ",
  productDetailsTxt: "تفاصيل المنتج",
  pckgDetailsTxt: "تفاصيل الباكيج",
  ratesCountTxt: " عدد التقييمات",
  lastsTxt: "المتبقي",
  shareTxt: "مشاركة",
  addToCartTxt: "أضف للسلة",
  addToFavTxt: "أضف للمفضلة",
  commentsTxt: "تعليقات",
  noCommentsTxt: "لا يوجد تعليقات حتى الآن",
  DescriptionTxt: "الوصف",
  theCommentsTxt: "التعليقات",
  aboutProd: "حول المنتج",
  showMoreComments: "عرض المزيد من التعليقات",
  writeCommentHere: "أضف التعليق هنا",
  writeCommentTxt: "أضف تعليق",
  addTxt: "أضف",
  addAmmountTxt: "أضف بقيمة",
  toGetfreeshipping: "للحصول على الشحن المجاني",
  youGotfreeshipping: "لقد حصلت على الشحن المجاني",
  evaluatePrdctTitle: "قيم تجربتك",
  tllUsUrExprmntDtld: "أخبرنا عن تجربتك",
  evaluatePrdct: "قيم تجربتك لهذا المنتج",
  contentCommingSoon: "سيتم توفير المعلومات قريبا",
  experimentsInfo: "تجارب ومعلومات",
  addedToCartMessage: "تم اضافة المنتج الى السلة",
  goToSettingsTxt: "السماح من الإعدادات",
  dontGoSettingsTxt: " عدم السماح",
  /* contact us */
  saveTxt: "حفظ",
  name: "الاسم",
  nameTxt: "الاسم الكامل",
  email: "البريد الإلكتروني",
  phone: "الجوال*",
  msg: "نص الرسالة*",
  send: "إرسال",
  adrs: "العنوان",
  validateMsg: "الرسالة يجب أن تكون أكثر من 5 أحرف",
  validatePhone: "تأكد من رقم الجوال",
  validatepassword: "تأكد من كلمة المرور",
  withTxt: " ب",
  Qm: " ؟ ",
  /* Login */
  countryTxt: "الدولة",
  phonePlacTxt: "رقم الجوال",
  phoneTxt: "e.g 5xxxxxxxx",
  loginTxt: "دخول",
  resetPasswordTxt: "إعادة كلمة المرور",
  plsEntrPwdTxt: "أدخل كلمة المرور",
  confirmPassTxt: "يجب تطابق كلمتي المرور",
  registerTxt: "تسجيل",
  activateAccountTxt: "تفعيل ",
  activateMsgTxt:
    " فضلا، قم بإدخال رمز التحقق المكون من 4 أرقام المرسل إلى جوالك رقم ",
  activateEmailMsgTxt:
    " فضلا، قم بإدخال رمز التحقق المكون من 4 أرقام المرسل إلى بريدك الإلكتروني ",
  orTxt: "أو",
  plsLgnFrst: "من فضلك سجل دخول أولا",
  verifyTxt: "تحقق الآن",
  didnotReceiveCodeTxt: " لم تتلق رمز التحقق ؟",
  activateTitleTxt: "التحقق من رقم الجوال",
  resendTxt: "أرسله مرة أخرى",
  callMeTxt: "اتصل بي",
  passwordTxt: "كلمة المرور",
  forgotPasswordTxt: "نسيت كلمة المرور ؟",
  allowUsingLocation: "الرجاء السماح باستخدام الموقع من الاعدادات",
  /* Register */
  SettingsTxt:"الاعدادات",
  previousMember: "عضو سابق ؟",
  genderTxt: "النوع",
  nwPswrdSnt: "تم إرسال كلمة مرور جديدة",
  crtNewAccntTxt: " إنشاء حساب جديد ",
  mustAcceptPrivacy: "أوافق على الشروط والأحكام وسياسة الخصوصية",
  youmustAcceptPrivacy: "يجب الموافقة على  الشروط والأحكام وسياسة الخصوصية",
  passwordConfirmTxt: "تأكيد كلمة المرور",
  setNewPasswordTxt: " تعيين كلمة المرور",
  passwordMustBeFourTxt: "حقل كلمة المرور يجب ان يكون 4 خانات فأكثر",
  typeNewPasswordHere: "أدخل كلمة المرور الجديدة أدناه",
  male: "ذكر",
  female: "أنثى",
  paymentMethodsnotaplicableTxt: "طريقة الدفع غير متاحة لهذه العملة",
  /* Client Nav */
  myOrdersTxt: "طلبياتي",
  myFavsTxt: "مفضلتي",

  /* Orders */
  orderIdTxt: "رقم الطلبية",

  /* Profile */

  updateTxt: "تعديل",
  myProfile: "الصفحة الشخصية",
  emailTxt: "البريد الإلكتروني",
  EnteremailTxt: "ادخل البريد الإلكتروني",
  checkemailTxt: "تأكد من البريد الإلكتروني",
  prfrdCntctLng: "لغة التواصل المفضلة",
  arabicTxt: "عربي",
  englishTxt: "انجليزي",
  itemAlreadyInCartTxt: "تمت إضافة المنتج الى السلة مسبقا",
  updateShpmntAdrsScreen: "تعديل عنوان شحن",
  packagesTxt: "العروض والباكيجات",
  packagesOnlyTxt: "الباكيجات",
  writePrdctNmHr: "اكتب كلمة البحث هنا",
  willTellYouWhenAvailable: "سنخبرك عند التوفر",
  tellMeWhenAvailable: "أخبرني عندما يتوفر",
  /* Cart Review */
  couponCodeTxt: "كود الكوبون",
  subTotalTxt: "الإجمالي الجزئي",
  shippingChargesTxt: "تكلفة الشحن",
  couponValueTxt: "قيمة الكوبون",
  couponAppliedTxt: "تم تطبيق الكوبون",
  ApplyingCouponTxt: "جاري تطبيق الكوبون",
  myCreditTxt: "رصيدي",
  applyTxt: "تطبيق",
  sendTxt: "أرسل",
  citynotSupportedTxt: "المدينة غير مدعومة ",
  countrynotSupportedTxt: "الدولة غير مدعومة ",
  qtyTxt: "الكمية",
  weightTxt: "الوزن",
  finalTotalTxt: "الإجمالي النهائي",
  addShipmentAdrsTxt: "أضف عنوان شحن",
  shipmentAdrsTxt: "عنوان شحن",
  adrsTxt: "أدخل تفاصيل العنوان",
  chooseShipmentAdrsTxt: "اختر عنوان شحن أولا",
  continueAsVisitorTxt: "أكمل كزائر",
  visaMasterTxt: "فيزا/ماستر كارد",
  rateTitleTxt: "كيف ترى تطبيق ارجان باكيج ؟",
  rateContentTxt: "قم بتقييم التطبيق بمتجر التطبيقات واكتب لنا رأيك",
  cargoFirmsTxt: "شركات الشحن المتاحة حسب عنوان الشحن",
  cityTxt: " اختر المدينة",
  WriteCityNameTxt: " أدخل اسم المدينة",
  paymentMethodsTxt: "طريقة الدفع",
  firstNameTxt: "الاسم الأول",
  lastNameTxt: "الاسم الثاني",
  receiverMobileTxt: "54xxxxxxx",
  receiverMobile2Txt: "رقم المستلم 2",
  blockNrTxt: "رقم البلوك",
  postalCodeTxt: "الرمز البريدي",
  cardHolderTxt: "على البطاقة",
  expiryDateTxt: "تاريخ الصلاحية",
  cvvTxt: "الرقم السري",
  cardNumberTxt: "رقم البطاقة",
  amountTxt: "المبلغ",
  transDateTxt: "تاريخ التحويل",
  dateTxt: "التاريخ",
  refNoTxt: "الرقم المرجعي",
  storeAccountsTxt: "رقم حساب المتجر",
  ACcountTxt: "حساب",
  storeBankNameTxt: "اسم البنك الخاص بالمتجر ",
  bankNameTxt: "اسم البنك",
  soonTxt: "قريبا",
  ibansTxt: "الآي بان",
  yourBankNameTxt: "اسم البنك الذي حولتم منه",
  yourAccountNoTxt: "رقم حسابكم البنكي",
  uploadClrReceiptTxt: "ارفع إيصال الحوالة واضحا",
  uploadReceiptTxt: "ارفع إيصال حوالة",
  paymentUponReceiptTxt: "دفع عند الاستلام",
  completeTxt: "إتمام",
  backTxt: "رجوع",
  tutorialMsgaddadrs: "اضغط هنا لاضافة عنوان شحن",
  tutorialMsgChosadrs: "اضغط على العنوان لاختياره",
  tutorialMsgChospmnt: "اضغط لاختيار طريقة الدفع",
  editTxt: "تعديل",
  editShipmentAdrsTxt: "قم بتعديل عنوان الشحن",
  statusTxt: "الحالة",
  shoppingCart: "سلة المشتريات",
  footerCart: "السلة",
  noItems: "لا توجد منتجات في السلة",
  usingRceiptsTxt: "تحويل بنكي ",
  choose: "اختر",
  searchTxt: "بحث",
  monthTxt: "الشهر",
  yearTxt: "السنة",
  payTxt: "ادفع",
  paymentuponcreditcardTxt: " الدفع بواسطة البطاقة الائتمانية",
  descriptionTxt: "تفاصيل المنتج",
  beneficiaryNameTxt: "اسم صاحب الحساب",
  doneTxt: "تم التسليم",
  pendingTxt: "قيد الانتظار",
  cancelledTxt: "ملغاة",
  cancelTxt: "إلغاء",
  sentToCargoTxt: "تم التسليم للشحن",
  shoulddeleveredTxt: "ينبغي استلام الطلبية",
  returnedTxt: "مرجعة",
  orderDetailsTxt: "تفاصيل الطلبية",
  loadingTxt: "جاري التحميل",
  loadingloginTxt: "جاري تسجيل الدخول",
  prpsdPrdcts: "منتجات مقترحة",
  discountTxt: "خصم",
  customsRspnsbltyMsgTxt:
    "نخلي مسؤوليتنا عن الضرائب المقامة على التخليص الجمركي",
  editAdrsFirst: "معلومات العنوان غير مكتملة قم بتعديله اولاً",
  SAR: "ر.س",
  EGP: "ج.م",
  USD: "د.أ",
  AED: "د.إ",
  conditionsTxt: "الشروط والأحكام",
  privacyTxt: "سياسة الخصوصية",
  andTxt: "و",
  iAcceptTxt: "أوافق على",
  returntoPayPageTxt: "العودة إلى طلباتي",
  noResultFound: "لا توجد نتائج",
  receiverFirstNameTxt: "الاسم الكامل",
  receiverLastNameTxt: "اسم العائلة للمستلم",
  alertTxt: "تنبيه",
  depsTxt: "الأقسام",
  okTxt: "حسنا",
  sorryTxt: "نأسف!",
  greatTxt: "رائع!",
  priceTxt: "السعر",
  txRspnsbltyMsg: "نخلي مسؤوليتنا عن الضرائب المقامة على التخليص الجمركي",
  preferredCargoTxt: "شركة الشحن المفضلة",
  cannotFindcityTxt: "لا تجد مدينتك ؟",
  facingIssueTxt: "هل تواجه مشكلة ؟",
  /*   Errors   */
  creditPaymentError: "حصل خطأ!",
};
