import { ar } from '../../../lang/ar';

const INITIAL_STATE = {slctdLng: ar};
export const LanguageReducer  = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'CHANGE_LANG':
      return {
        slctdLng: action.payload
      };
    default: 
     return state;
  }
};

export default LanguageReducer;