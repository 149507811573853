export const en = {
  langCode: "en",
  langDir: "ltr",
  langAlign: "left",
  lngFlex: "flex-start",
  lngAlnRvrs: "right",
  langRow: "row-reverse",
  langRowRvrs: "row",
  appTitle: "Restaurant App",
  menusTxt: "Menus",
  SettingsTxt: "Settings",
  ACcountTxt: "Account",

  visitor: "Visitor",
  welcome: "Welcome",
  gram: "In gm",
  AddNewItem: "Add New menu Item",
  UpdateMenuItem: "Update Item",
  EnterEnglishNumberTxt: "Be sure to enter English Numbers",
  EnterEnglishTxt: "Be sure to enter the name in English",
  home: "Home",
  Tables: "Tables",
  Restaurants: "Restaurants",
  Reserved: "Reserved",
  Available: "Available",
  Chairs: "Chairs",

  aboutUs: "About Us",
  policyTxt: "Terms & Conditions",
  changeTxt: "Change",
  allProducts: "All Products",
  offers: "Offers",
  loginByPhone: "Login By Phone Number",
  loginByEmail: "Login By Email",
  withTxt: " with ",
  pckgDetailsTxt: "Packages Details",
  Qm: " ? ",
  showMoreComments: "Show more comments",
  noCommentsTxt: "No comments yet",
  DescriptionTxt: "Description",
  theCommentsTxt: "Comments",
  aboutProd: "About",
  allowUsingLocation: "Allow using location from Settings",
  goToSettingsTxt: "Allow in settings",
  dontGoSettingsTxt: " Don't allow",
  faq: "Faq",
  getMyLocation: "My Location",

  contactUs: "Contact Us",
  welcomeContacter: "Welcome dear, How can we help you ?",
  threelettersWarnings: "Address should be at least 3 letters",
  checkRecieverName: "Reciever Name is required",
  sendTxt: "Send",
  login: "Login",
  willTellYouWhenAvailable: "You'll be Notified",
  tellMeWhenAvailable: "Notify Me",
  noConnectionTxt: "No Internet Connection",
  dontHvAccnt: "Don't have an account ? ",
  citynotSupportedTxt: "The City is not Supported",
  countrynotSupportedTxt: "The Country is not Supported",
  tutorialMsgaddadrs: "Press here to add Address",
  tutorialMsgChosadrs: "Press on address to select",
  tutorialMsgChospmnt: "Choose payment method",
  less: "less",
  saveTxt: "save",

  signIn: "Login",
  alreadyHaveAcct: " Already Have An Account ? ",
  logoutTxt: "Logout",
  register: "Register",
  updateProfile: "My Profile",
  footerMyProfile: "Profile",
  updateApp: "Update",
  updateNeeded: "Update Needed",
  theLanguage: "Language",
  logOutDoubleCheck: "Are You sure you want to logout",
  logOutFullText: "Logout",
  addedRecentlyTxt: "Added Recently",
  mostSoldTxt: "Most Sold",
  more: "More",
  editShipmentAdrsTxt: "Edit Shipping address",
  /* product details */
  priceIncludeTaxes: "Includes VAT",
  productDetailsTxt: "Product Details",
  ratesCountTxt: "Rates Count",
  lastsTxt: "Lasts",
  shareTxt: "Share",
  addToCartTxt: "Add To Cart",
  addToFavTxt: "Add To Favourites",
  commentsTxt: "Comments",
  writeCommentHere: "Add Comment Here",
  writeCommentTxt: "Add Comment",
  addTxt: "Add",
  tllUsUrExprmntDtld: "Tell us about your experiment",
  evaluatePrdctTitle: "Your Experiment",
  evaluatePrdct: "Comment & Share Your Experiment",
  contentCommingSoon: "Soon",
  experimentsInfo: "Experiments",
  addedToCartMessage: "Product Added to Cart",
  paymentMethodsnotaplicableTxt:
    "Payment Method is not applicable with the currency",
  rateTitleTxt: "How did you find Argan Package App?",
  rateContentTxt:
    "Rate the App in the App Store, And tell Us what do you think.",
  /* contact us */
  editTxt: "Edit",
  name: "Name",
  nameTxt: "Full Name",
  email: "Email",
  phone: "Phone *",
  msg: "Msg *",
  send: "Send",
  adrs: "Adrs",
  validateMsg: "Msg is to short",
  validatePhone: "Validate Your Phone",

  /* Login */
  countryTxt: "Country",
  phonePlacTxt: "Phone Number",
  phoneTxt: "e.g 5xxxxxxxx",
  loginTxt: "Login",
  resetPasswordTxt: "Reset Password",
  plsEntrPwdTxt: "Enter Password",
  validatepassword: "Enter correct Password",
  checkemailTxt: "Enter Correct Email",
  registerTxt: "Register",
  youmustAcceptPrivacy: "You Must Accept Terms Of Use",
  activateAccountTxt: "Activate",
  activateTitleTxt: "Phone verification",
  activateMsgTxt:
    "Please enter the 4-digits verification code sent to your phone",
  activateEmailMsgTxt:
    "Please enter the 4-digits verification code sent to your Email",
  orTxt: "Or",
  passwordMustBeFourTxt: "Password must be 4 digits or higher",
  checkfullName: "Enter Correct Full name",
  completeMyData: "Complete My Data",
  yourDataIsIncmplt: "Your Data is incomplete",
  addEmailInMyProfile: "Add email in My Profile page",
  wlcmlgnTxt: "Welcome, Please Login",
  MyAdressesTxt: "My Addresses",
  plsLgnFrst: "Please Login First",
  loginByPwd: "Login by Password",
  EnteremailTxt: "Enter Email",
  verifyTxt: "Verify Now",
  didnotReceiveCodeTxt: "Didn't receive code ? ",
  resendTxt: "Resend Code",
  callMeTxt: "Call Me",
  passwordTxt: "Password",
  forgotPasswordTxt: "Forgot Password ?",
  prdctRviewTxt: "comment about the product ?",
  /* Register */
  previousMember: "Already Member ?",
  genderTxt: "Gender",
  nwPswrdSnt: "A New Password Has Been Sent",
  deleteTxt: "Delete",
  crtNewAccntTxt: "Create New Account",
  mustAcceptPrivacy: "You Must Accept Terms Of Use",
  passwordConfirmTxt: "Confirm Password",
  setNewPasswordTxt: "Set New Password",
  confirmPassTxt: "Confirm The Password",
  typeNewPasswordHere: "Type a new password at the input",
  male: "Male",
  female: "Female",
  VerifiedPurchase: "Verified Purchase",
  addAmmountTxt: "Add the amount of",
  toGetfreeshipping: "to get free shipping",
  youGotfreeshipping: "You've got free shipping",
  /* Client Nav */
  myOrdersTxt: "My Orders",
  myFavsTxt: "My Favs",

  /* Orders */
  orderIdTxt: "Order ID",

  /* Profile */

  updateTxt: "Update",
  myProfile: "My Profile",
  emailTxt: "Email",
  prfrdCntctLng: "Preferred Contact Language",
  arabicTxt: "Arabic",
  englishTxt: "English",

  updateShpmntAdrsScreen: "Update Adrs",
  packagesTxt: "Offers And Packages",
  packagesOnlyTxt: "Packages",
  footerPackages: "Packages",
  writePrdctNmHr: "Write your search here",
  itemAlreadyInCartTxt: "Item Already in cart",
  /* Cart Review */
  notAvailableTxt: "Out of stock",
  couponCodeTxt: "Coupon Code",
  subTotalTxt: "Sub Total",
  shippingChargesTxt: "Shipping Cost",
  couponValueTxt: "Coupon Value",
  couponAppliedTxt: "Coupon Applied",
  ApplyingCouponTxt: "Applying Coupon ",
  applyTxt: "Apply",
  myCreditTxt: "Credit",
  qtyTxt: "Quantity",
  weightTxt: "Weight",
  finalTotalTxt: "Total",
  totalTxt: "Total",
  addShipmentAdrsTxt: "Add Adrs",
  shipmentAdrsTxt: "Shipment Adrs",
  chooseShipmentAdrsTxt: "Firstly, Choose Shipment Adrs",
  adrsTxt: "Add address details",
  continueAsVisitorTxt: "Continue as a visitor",
  visaMasterTxt: "Visa / Mastercard",
  cargoFirmsTxt: "Cargo Firms",
  cityTxt: "Choose City",
  paymentMethodsTxt: "Payment Methods",
  firstNameTxt: "First Name",
  lastNameTxt: "Last Name",
  receiverMobileTxt: "Receiver Mobile 1",
  receiverMobile2Txt: "Receiver Mobile 2",
  blockNrTxt: "Block Nr",
  postalCodeTxt: "Postal Code",
  cardHolderTxt: "On Card",
  expiryDateTxt: "Expiry Date",
  cvvTxt: "CVV",
  cardNumberTxt: "Card Number",
  amountTxt: "Amount",
  transDateTxt: "Transfer Date",
  dateTxt: "Date",
  refNoTxt: "Ref. Nr.",
  storeAccountsTxt: "Store Bank Accounts",
  storeBankNameTxt: "Store Bank Name",
  returntoPayPageTxt: "Retrun to My Orders",
  yourBankNameTxt: "Your Bank Name",
  bankNameTxt: "Bank Name",
  ibansTxt: "IBAN",
  soonTxt: "Soon",
  yourAccountNoTxt: "Your Bank Account",
  uploadReceiptTxt: "Upload a Receipt",
  uploadClrReceiptTxt: "Upload a clear receipt",
  paymentuponcreditcardTxt: "Pay using Credit Card",
  paymentUponReceiptTxt: "Upon Receipt",
  completeTxt: "Complete",
  backTxt: "Back",
  statusTxt: "Status",
  shoppingCart: "Shopping Cart",
  footerCart: "Cart",
  enterNameOnCardTxt: "Enter the Name on the card",
  noItems: "No Items Found",
  usingRceiptsTxt: "Bank Transfer",
  choose: "Choose",
  searchTxt: "Search",
  monthTxt: "Month",
  yearTxt: "Year",
  payTxt: "Pay",
  descriptionTxt: "Description",
  beneficiaryNameTxt: "Beneficiary Name",
  doneTxt: "Done",
  pendingTxt: "Pending",
  cancelledTxt: "Cancelled",
  cancelTxt: "Cancel",
  sentToCargoTxt: "Sent To Cargo",
  shoulddeleveredTxt: "Order should be received",
  loadingloginTxt: "Loging in",
  returnedTxt: "Returned",
  orderDetailsTxt: "Order Details",
  show: "Show",
  trackTxt: "Track",
  loginToCommentTxt: "Login To Leave Comment",
  editAdrsFirst: "Address is not complete, Edit it first",
  shippingTxt: "Shipping",
  loadingTxt: "Loading",
  prpsdPrdcts: "Proposed Products",
  discountTxt: "Dis.",
  customsRspnsbltyMsgTxt:
    "We disclaim all responsibility for taxes on customs clearance",
  SAR: "SAR",
  EGP: "EGP",
  USD: "USD",
  AED: "AED",
  noResultFound: "No Result Found",
  cannotFindcityTxt: "Can't find your city ?",
  facingIssueTxt: "Facing an issue ?",
  conditionsTxt: "Conditions&Terms",
  privacyTxt: "Privacy",
  andTxt: " And ",
  iAcceptTxt: "I agree ",
  WriteCityNameTxt: " Enter City Name",
  receiverFirstNameTxt: "Receiver Full Name",
  receiverLastNameTxt: "Receiver Last Name",
  alertTxt: "Alert",
  depsTxt: "Categories",
  okTxt: "Ok",
  sorryTxt: "Sorry!",
  greatTxt: "Great!",
  priceTxt: "Price",
  txRspnsbltyMsg:
    "We disclaim all responsibility for taxes on customs clearance",
  preferredCargoTxt: "Prefered Cargo",

  /*   Errors   */
  creditPaymentError: "Something went wrong !",
};
