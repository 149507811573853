

const restaurants = [
  {
    id: 1,
    nameEn: "The Gourmet Kitchen",
    nameAr: "مطبخ الذواقة",
    cuisine: "Italian",
    branches: [
      {
        branchId: 1,
        nameEn: "Downtown Branch",
        nameAr: "فرع وسط المدينة",
        location: "123 Main St, Downtown",
        contact: "0123-456-789",
        dishes: [
          {
            dishId: 1,
            nameEn: "Margherita Pizza",
            nameAr: "بيتزا مارغريتا",
            price: 12.99,
            descriptionEn: "Classic pizza with mozzarella cheese and tomato sauce",
            descriptionAr: "بيتزا كلاسيكية مع جبنة الموتزاريلا وصلصة الطماطم",
          },
          {
            dishId: 2,
            nameEn: "Spaghetti Carbonara",
            nameAr: "سباغيتي كاربونارا",
            price: 15.99,
            descriptionEn: "Traditional Italian pasta with pancetta, eggs, and Parmesan cheese",
            descriptionAr: "معكرونة إيطالية تقليدية مع لحم مقدد وبيض وجبن بارميزان",
          },
        ],
      },
      {
        branchId: 2,
        nameEn: "Uptown Branch",
        nameAr: "فرع أبتاون",
        location: "456 Uptown Ave, Uptown",
        contact: "0987-654-321",
        dishes: [
          {
            dishId: 3,
            nameEn: "Lasagna",
            nameAr: "لازانيا",
            price: 14.99,
            descriptionEn: "Layered pasta with meat sauce, ricotta, and mozzarella",
            descriptionAr: "معكرونة طبقية مع صلصة اللحم والريكوتا والموتزاريلا",
          },
          {
            dishId: 4,
            nameEn: "Bruschetta",
            nameAr: "بروشيتا",
            price: 8.99,
            descriptionEn: "Grilled bread topped with diced tomatoes, basil, and olive oil",
            descriptionAr: "خبز مشوي مع مكعبات الطماطم والريحان وزيت الزيتون",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    nameEn: "Spice Heaven",
    nameAr: "جنة التوابل",
    cuisine: "Indian",
    branches: [
      {
        branchId: 3,
        nameEn: "East Side Branch",
        nameAr: "فرع الجانب الشرقي",
        location: "789 East Side Blvd, East Town",
        contact: "0223-456-789",
        dishes: [
          {
            dishId: 5,
            nameEn: "Butter Chicken",
            nameAr: "دجاج بالزبدة",
            price: 13.99,
            descriptionEn: "Chicken cooked in a creamy tomato sauce with Indian spices",
            descriptionAr: "دجاج مطبوخ في صلصة طماطم كريمية مع توابل هندية",
          },
          {
            dishId: 6,
            nameEn: "Garlic Naan",
            nameAr: "نان بالثوم",
            price: 3.99,
            descriptionEn: "Leavened flatbread with garlic and herbs",
            descriptionAr: "خبز مسطح مخمر بالثوم والأعشاب",
          },
        ],
      },
      {
        branchId: 4,
        nameEn: "West Side Branch",
        nameAr: "فرع الجانب الغربي",
        location: "321 West Side St, West Town",
        contact: "0337-654-987",
        dishes: [
          {
            dishId: 7,
            nameEn: "Lamb Rogan Josh",
            nameAr: "روجان جوش الضأن",
            price: 16.99,
            descriptionEn: "Slow-cooked lamb in a rich, spiced sauce",
            descriptionAr: "لحم الضأن المطهو ببطء في صلصة غنية بالتوابل",
          },
          {
            dishId: 8,
            nameEn: "Samosa",
            nameAr: "سمبوسة",
            price: 5.99,
            descriptionEn: "Fried pastry filled with spiced potatoes and peas",
            descriptionAr: "عجينة مقلية محشوة بالبطاطا المتبلة والبازلاء",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    nameEn: "Spice Heaven",
    nameAr: "جنة التوابل",
    cuisine: "Indian",
    branches: [
      {
        branchId: 3,
        nameEn: "East Side Branch",
        nameAr: "فرع الجانب الشرقي",
        location: "789 East Side Blvd, East Town",
        contact: "0223-456-789",
        dishes: [
          {
            dishId: 5,
            nameEn: "Butter Chicken",
            nameAr: "دجاج بالزبدة",
            price: 13.99,
            descriptionEn: "Chicken cooked in a creamy tomato sauce with Indian spices",
            descriptionAr: "دجاج مطبوخ في صلصة طماطم كريمية مع توابل هندية",
          },
          {
            dishId: 6,
            nameEn: "Garlic Naan",
            nameAr: "نان بالثوم",
            price: 3.99,
            descriptionEn: "Leavened flatbread with garlic and herbs",
            descriptionAr: "خبز مسطح مخمر بالثوم والأعشاب",
          },
        ],
      },
      {
        branchId: 4,
        nameEn: "West Side Branch",
        nameAr: "فرع الجانب الغربي",
        location: "321 West Side St, West Town",
        contact: "0337-654-987",
        dishes: [
          {
            dishId: 7,
            nameEn: "Lamb Rogan Josh",
            nameAr: "روجان جوش الضأن",
            price: 16.99,
            descriptionEn: "Slow-cooked lamb in a rich, spiced sauce",
            descriptionAr: "لحم الضأن المطهو ببطء في صلصة غنية بالتوابل",
          },
          {
            dishId: 8,
            nameEn: "Samosa",
            nameAr: "سمبوسة",
            price: 5.99,
            descriptionEn: "Fried pastry filled with spiced potatoes and peas",
            descriptionAr: "عجينة مقلية محشوة بالبطاطا المتبلة والبازلاء",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    nameEn: "Spice Heaven",
    nameAr: "جنة التوابل",
    cuisine: "Indian",
    branches: [
      {
        branchId: 3,
        nameEn: "East Side Branch",
        nameAr: "فرع الدائري الشرقي",
        location: "789 East Side Blvd, East Town",
        contact: "0223-456-789",
        dishes: [
          {
            dishId: 5,
            nameEn: "Butter Chicken",
            nameAr: "دجاج بالزبدة",
            price: 13.99,
            descriptionEn: "Chicken cooked in a creamy tomato sauce with Indian spices",
            descriptionAr: "دجاج مطبوخ في صلصة طماطم كريمية مع توابل هندية",
          },
          {
            dishId: 6,
            nameEn: "Garlic Naan",
            nameAr: "نان بالثوم",
            price: 3.99,
            descriptionEn: "Leavened flatbread with garlic and herbs",
            descriptionAr: "خبز مسطح مخمر بالثوم والأعشاب",
          },
        ],
      },
      {
        branchId: 4,
        nameEn: "West Side Branch",
        nameAr: "فرع الدائري الغربي",
        location: "321 West Side St, West Town",
        contact: "0337-654-987",
        dishes: [
          {
            dishId: 7,
            nameEn: "Lamb Rogan Josh",
            nameAr: "روجان جوش الضأن",
            price: 16.99,
            descriptionEn: "Slow-cooked lamb in a rich, spiced sauce",
            descriptionAr: "لحم الضأن المطهو ببطء في صلصة غنية بالتوابل",
          },
          {
            dishId: 8,
            nameEn: "Samosa",
            nameAr: "سمبوسة",
            price: 5.99,
            descriptionEn: "Fried pastry filled with spiced potatoes and peas",
            descriptionAr: "عجينة مقلية محشوة بالبطاطا المتبلة والبازلاء",
          },
        ],
      },
    ],
  },
];
  
  export const Restaurant = (state = restaurants, action) => {
    switch (action.type) {
      case 'Add_RESTAURANT':
        return {
          ...state.payload
        };
      case 'ADD_BRANCH':
        return {
          ...state.payload,
        };
      default:
        return state;
    }
  };




