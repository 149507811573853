import { createStore, combineReducers } from 'redux';
import Language from './reducers/language';
import {UserData} from './reducers/userData';
import {Restaurant} from './reducers/retaurants';

// Combine all the reducers into one root reducer
const rootReducer = combineReducers({
  Language,
  UserData,
  Restaurant
});

// Function to save the state to sessionStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (e) {
    console.error("Could not save state", e);
  }
};

// Function to load the state from sessionStorage
const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.error("Could not load state", e);
    return undefined;
  }
};

// Load the persisted state from sessionStorage
const persistedState = loadState();

// Create the Redux store with the persisted state
const store = createStore(
  rootReducer,
  persistedState
);

// Subscribe to the store and save the state to sessionStorage on every change
store.subscribe(() => {
  saveState(store.getState());
});

// Export the store for use in your application
export { store };
