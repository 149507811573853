import React, { useState } from "react";

import { Grid, Divider, Typography, Box, ButtonBase } from "@mui/material";
import TableCard from "./Table";
import GContainer from "../component/GContainer";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
const Tables = () => {
  const tablesd = [
    {
      tableNumber: 1,
      initialChairs: 4,
      initialReserved: 0,
      
      count: 0,
      
    },
    {
      tableNumber: 4,
      initialChairs: 6,
      initialReserved: 1,
      date: "20-12-2024",
      time: "12:24 AM",
      count: 3,
      reserverName: "غانم كيلاني",
      reserverPhone: 966593311312,
    },
    {
      tableNumber: 3,
      initialChairs: 2,
      initialReserved: 2,
      date: "20-12-2024",
      time: "12:24 AM",
      count: 3,
      reserverName: "عبدالله المبارك",
      reserverPhone: 966593311312,
    },
    {
      tableNumber: 4,
      initialChairs: 8,
      initialReserved: 0,
      count: 0,
    },
  ];
  const [tables, setTables] = useState(tablesd);
  const language = useSelector((state) => state.Language.slctdLng);
  const AddNewTable = () => {
    console.log(tables)
    setTables([
      ...tables,
      {
        tableNumber: 4,
        initialChairs: 0,
        initialReserved: 0,
        date: "",
        time: "",
        count: 0,
      },
    ]);
  };
  return (
    <GContainer>
      <Box p={1} my={1}>
        <Typography variant="h4" align="center" gutterBottom>
          {language.Tables}
        </Typography>
        <Box
        // alignContent={"flex-end"}
        // justifyContent={"flex-end"}
        // alignItems={"end"}
        >
          <Box flexDirection={"row-reverse"} display={"flex"} mx={2}>
            <Box flexDirection={"row"} display={"flex"}>
              <Typography fontSize={10} mx={1} variant="body1">
                حجز مؤكد
              </Typography>
              <Box height={10} width={10} bgcolor={"green"} />
            </Box>
          </Box>
          <Box flexDirection={"row-reverse"} display={"flex"} mx={2}>
            <Box flexDirection={"row"} display={"flex"}>
              <Typography fontSize={10} mx={1} variant="body1">
                حجز غير مؤكد
              </Typography>
              <Box height={10} width={10} bgcolor={"blue"} />
            </Box>
          </Box>
          <Box flexDirection={"row-reverse"} display={"flex"} mx={2}>
            <Box flexDirection={"row"} display={"flex"}>
              <Typography fontSize={10} mx={1} variant="body1">
                لا يوجد حجز
              </Typography>
              <Box height={10} width={10} bgcolor={"red"} />
            </Box>
          </Box>
        </Box>

        <Divider />
      </Box>

      <Grid container direction={language.langRowRvrs}>
        {tables.map((table) => (
          <Grid item xs={4} sm={4} md={3} lg={2} key={table.tableNumber}>
            <TableCard {...table} />
          </Grid>
        ))}
        <Grid
          item xs={4} sm={4} md={3} lg={2}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          display="flex"
          // bgcolor={"red"}
          bgcolor={"#f9f9f9"}
          
        >
          <ButtonBase
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            display="flex"
            onClick={() => AddNewTable()}
            // onClick={handleClick}
            sx={{ cursor: "progress" }}
          >
            <Add sx={{ fontSize: 50, color: "gray" }} />
          </ButtonBase>
        </Grid>
      </Grid>
    </GContainer>
  );
};

export default Tables;
