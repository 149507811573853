import React from "react";
import Styles from './styles'

const Menu = ({ items }) => {
  return (
    <div className="section-center" style={Styles.sectionCenter} >
      {items.map((item) => {
        const { id, title, img, desc, price } = item;
        return (
          <article key={id} className="menu-item"style={Styles.menuItem}>
            <img src={img} alt={title} className="photo"style={Styles.photo} />
            <div className="item-info" style={Styles.itemInfoHeader}>
              <header>
                <h4 style={Styles.itemInfoH4}>{title}</h4>
                <h4 className="price" style={Styles.price}>${price}</h4>
              </header>
              <p className="item-text" style={Styles.itemText}>{desc}</p>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Menu;
